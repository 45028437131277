import React from "react";
import { css } from "@emotion/core";
import { useStaticQuery, graphql } from "gatsby";

import { mediaQuery, pixelsToRem, createSpacingUnitMediaQueries } from "../utils/css-helpers";
import { BREAKPOINTS } from "../css-vars";

import Img from "gatsby-image/withIEPolyfill";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import Hero from "../components/Hero";
import CallToAction from "../components/CallToAction";
import ResponsiveContainer from "../components/ResponsiveContainer";

const LoginPage = () => {
  const { contentfulLoginPage: data } = useStaticQuery(graphql`
    query {
      contentfulLoginPage {
        seoTitle
        seoMetaDescription {
          seoMetaDescription
        }
        services {
          id
          link
          logo {
            title
            localFile {
              childImageSharp {
                fixed(height: 60) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  `);

  if (data.seoMetaDescription) {
    // LongText fields in Contentful have a weird structure: if empty they return null,
    // if filled they return an object with the actual content in a homonym child...
    // https://github.com/gatsbyjs/gatsby/issues/3205
    data.seoMetaDescription = data.seoMetaDescription.seoMetaDescription;
  }

  return (
    <Layout hero={<Hero title="Login" showFindOutMore={false}/>}>
      <SEO title={data.seoTitle} description={data.seoMetaDescription}/>
      <ResponsiveContainer spacingTop="none" id="content-start" css={css`
        margin-top: ${pixelsToRem(-160)}; // WHY: Magic number that brings it back over the TopBackground

        ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
          margin-top: ${pixelsToRem(-100)}; // WHY: Magic number that brings it back over the TopBackground
        }

        ${mediaQuery({ minWidth: BREAKPOINTS.medium })} {
          pointer-events: none; // WHY: Allows click through on the underlapped elements
          margin-top: ${pixelsToRem(-280)}; // WHY: Magic number that positions it based on the heading
        }
        ${mediaQuery({ minWidth: BREAKPOINTS.large })} {
          margin-top: ${pixelsToRem(-402)}; // WHY: Equal to the height of the cutout from TopBackground.js
        }
      `}>
        <ul css={css`
          ${mediaQuery({ minWidth: BREAKPOINTS.medium })} {
            margin: 0 0 0 auto;
            pointer-events: auto;
            width: 66.667%;
          }
        `}>
          {data.services.map(service =>
            <li
              key={service.id}
              css={css`
                ${createSpacingUnitMediaQueries({
                  padding: `calc(SPACING_UNIT / 4) calc(SPACING_UNIT / 2)`,
                  'margin-bottom': `calc(SPACING_UNIT / 8)`,
                })}

                display: flex;
                flex-wrap: wrap;
                align-items: center;
                background: white;
                border: solid ${pixelsToRem(1)} rgba(0,30,96,.22);
                box-shadow: 0 ${pixelsToRem(5)} ${pixelsToRem(5)} rgba(86,101,130,.1);
                border-radius: ${pixelsToRem(4)};
              `}
            >
              <div css={css`
                background-size: contain;
                flex: 1;
                min-width: ${pixelsToRem(220)};

                ${createSpacingUnitMediaQueries({
                  'margin': `calc(SPACING_UNIT / 6) 0`,
                  'padding-right': `calc(SPACING_UNIT / 4)`,
                })}
              `}>
                <Img
                  fixed={service.logo.localFile.childImageSharp.fixed}
                  objectFit="contain"
                  objectPosition="0 50%"
                  alt={service.logo.title}
                  css={css`
                    ${createSpacingUnitMediaQueries({
                      'max-height': `calc(${pixelsToRem(30)} + (SPACING_UNIT / 6))`,
                    })}

                    width: 100%;
                  `}
                />
              </div>
              <CallToAction
                caption="Log In"
                link={service.link}
                level="small"
                spacingTop="none"
                spacingBottom="none"

                css={css`
                  ${createSpacingUnitMediaQueries({
                    'margin': `calc(SPACING_UNIT / 4) 0`,
                  })}

                  flex: 0 0 auto;
                `}
              />
            </li>
          )}
        </ul>
      </ResponsiveContainer>
    </Layout>
  );
};

export default LoginPage;
